<template>
  <h2 class="page-title">Explore</h2>
  <p class="error" v-if="error">{{ error }}</p>
  <div class="list-group">
    <div class="list-link" v-for="list in lists" v-bind:key="list.id">
      <router-link :to="'/' + list.user.username + '/' + list.url">
        <div class="list-image">
          <img :src="apiUrl + list.user.image" :alt="list.user.username">
        </div>
        <div class="list-details">
          <h3>{{ list.title }}</h3>
          <div class="username">{{ list.user.username }}</div>
        </div>
      </router-link>
    </div>
  </div>
  <InfiniteLoader @handler="infiniteHandler" />
</template>

<script>
import InfiniteLoader from '@/components/InfiniteLoader.vue'
import axios from 'axios'
import authHeader from '../services/auth-header'
import CacheService from '../services/cache.service'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'Search',
  components: {
    InfiniteLoader
  },
  data () {
    return {
      lists: [],
      page: 0,
      apiUrl: API_URL,
      error: ''
    }
  },
  created () {
    CacheService.load('search-cache')
  },
  methods: {
    infiniteHandler: function (stateHandler) {
      if (stateHandler.isFirst && CacheService.isValid()) {
        const cache = CacheService.get()
        this.lists = cache.data
        this.page = cache.current_page
        if (cache.current_page >= cache.last_page) {
          stateHandler.finished()
        } else {
          stateHandler.complete()
        }
      } else {
        this.getLists(stateHandler)
      }
    },
    getLists: function (stateHandler) {
      axios
        .get(API_URL + '/lists?page=' + (this.page + 1), {
          headers: authHeader()
        })
        .then(response => {
          this.lists.push(...response.data.data)
          CacheService.set({
            data: this.lists,
            current_page: response.data.current_page,
            last_page: response.data.last_page
          })
          this.page = response.data.current_page
          if (response.data.current_page < response.data.last_page) {
            stateHandler.complete()
          } else {
            stateHandler.finished()
          }
        })
        .catch(error => {
          this.error = error
          stateHandler.finished()
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="sass">
.list-link
  a
    display: flex
    align-items: center
.list-image
  width: 40px
  img
    border-radius: 50%
    vertical-align: middle
.list-details
  padding-left: 15px
  h3
    margin-bottom: 0
  .username
    color: #999
    font-size: 14px
</style>
